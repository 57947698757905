<template>
  <div class="my-20">
    <TransitionFade>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="absolute top-20 right-10">
          <SelectLangs />
        </div>
        <div class="py-14 mx-auto z-10">
          <div class="w-full">
            <div class="text-center">
              <div class="inline-flex space-x-6">
                <img src="https://acumbamail.com/static/public-theme/img/logos/acumbamail-black-logo.9f7ef42753fc.svg" class="w-60 my-10" />
                <div class="flex items-center" v-if="showPoweredBySetUp">
                  <div class="inline-flex items-center space-x-2 px-4 py-2 rounded-lg bg-white shadow-md poweredby">
                    <div class="text-sm font-semibold">{{ $t("acumbamailView.powered") }}</div>
                    <Brand size="small" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="multiAccount" class="hidden bg-white max-w-sm sm:rounded-lg mt-3 py-5 shadow-xl lg:block absolute ml-2 xl:ml-0 lg:left-3/4 xl:left-3/4 2xl:left-2/3">
              <ul class="flex md:flex-col md:justify-center gap-2 px-2">
                <h3 class="w-full px-2 text-gray-700 font-semibold leading-tight">{{ $$t("acumbamailView.projects") }}</h3>
                <div v-for="account in accounts" :key="account.id">
                  <div class="flex px-2 py-2 w-full text-center md:text-left transition-all duration-300 text-gray-600 font-semibold cursor-pointer rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" :class="[account.tenant.id == accountSelect.tenant.id ? 'bg-gray-100' : '']">
                    <div class="flex flex-row justify-start items-center w-full">
                      <p @click="changeAccount(account)" class="text-gray-500 text-xs">{{ account.tenant.name }} (#{{ account.tenant.id }})</p>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
            <div :class="['flex flex-col w-full mx-auto sm:rounded-lg shadow-xl md:max-w-lg bg-white mt-3 divide-y divide-dashed', shouldShowMapper ? 'md:max-w-6xl' : 'md:max-w-lg']">
              <div class="pt-8 py-4 px-6 md:px-8 space-y-4">
                <div class="w-full flex items-center py-2">
                  <h3 class="text-gray-600 font-semibold leading-tight">
                    {{ $t("acumbamailView.dashboard") }}
                    <span class="underline font-semibold text-gray-700">{{ accountSelect.tenant.name }}</span>
                  </h3>
                </div>
                <div clas="py-2">
                  <div class="rounded-md bg-yellow-50 p-4 mb-2">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <InformationCircleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-sm text-yellow-700">{{ $t("acumbamailView.minutes") }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="text-gray-500 text-sm py-2">{{ $t("acumbamailView.statusInfo") }}</p>
                <div class="w-full flex items-center pb-2">
                  <a href="https://acumbamail.com/" target="_blank" class="flex items-center w-full justify-center text-gray-600 hover:text-gray-700 rounded-lg shadow-md hover:bg-gray-50 border border-gray-200 transition relative hover:border-gray-300 focus:border-gray-300 focus:ring-gray-300 focus:outline-none focus:ring">
                    <div class="w-4/12 lg:w-5/12 flex flex-row justify-evenly items-center">
                      <img src="https://acumbamail.com/static/favico/Acumbamail/favicon-152.4f1b048ace6e.png" alt="acumbamail" class="h-6 w-6" />
                      <span class="px-0 py-3 flex-1 text-center font-semibold">{{ $t("acumbamailView.goAcumbamail") }}</span>
                    </div>
                  </a>
                </div>
              </div>
              <div>
                <div class="w-full flex flex-col justify-center items-center py-10" v-if="loading">
                  <Spinner color="blue" size="small" />
                </div>
                <div v-if="shouldShowMapper">
                  <div class="my-6 px-4">
                    <AutoMapper v-if="channelFrom.services && channelTo.services_output" :projectCredentialFromId="projectCredentialFrom.id" :projectCredentialToId="projectCredentialTo.id" :servicesFrom="channelFrom.services" :serviceTo="channelTo.services_output[0]" :integrations="project_connection.integrations ?? []" :channelFrom="channelFrom" :channelTo="channelTo" @dataFinish="finishIntegration" ref="automapper" />
                    <div class="flex items-center justify-end my-4">
                      <button class="btn btn-primary btn-xs" @click="completeIntegration">{{ $t("acumbamailView.saveChanges") }}</button>
                    </div>
                  </div>
                </div>
                <div v-if="shouldShowIntegration && !loading">
                  <div v-if="!project_connection.is_active" class="w-full flex flex-col justify-center items-start mt-4 ml-7">
                    <Button :label="$t('mailupView.reactive')" v-tooltip="'mailupView.reactiveMessage'" type="button" class="p-button-light shadow" @click="reactiveIntegrations()" />
                  </div>
                  <div class="w-full flex flex-col justify-center items-start">
                    <div v-for="integration in project_connection.integrations.filter((integration) => integration.service.name != 'Products')" :key="integration.id">
                      <div class="flex items-center px-7 py-4">
                        <div class="flex-1 flex items-center">
                          <div class="space-x-2 flex items-center">
                            <CheckCircleIcon class="mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                            <div class="items-center flex">
                              <h2 class="text-sm font-semibold text-gray-800">{{ integration.service.name }}</h2>
                            </div>
                            <div v-if="integration.sync_at" class="hidden md:block col-span-1">
                              <p class="text-xs text-gray-900">({{ $moment(integration.sync_at).format("YYYY-MM-DD HH:mm") }} {{ $t("acumbamailView.lastUpdated") }})</p>
                            </div>
                            <button v-show="showCopyButton && integration.service.name == 'Products'" @click="copyButton" title="Copy to clipboard" class="inline-flex justify-center items-center text-xs text-gray-500 dark:text-gray-300 hover:bg-gray-100 p-1 focus:border-gray-300 focus:ring-gray-200 focus:outline-none focus:ring rounded-md transition-opacity">
                              <TransitionFade>
                                <ClipboardCopyIcon class="h-5 w-5" aria-hidden="true" />
                              </TransitionFade>
                              <span class="ml-1.5">{{ !copyAction ? $t("mailup.copy") : $t("mailup.copied") }}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row justify-center w-full mb-4">
                      <Button :label="$t('mailup.editIntegrations')" type="button" class="p-button-light shadow" @click="enableEdit" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransitionFade>
  </div>
</template>

<script>
import TransitionFade from "@/components/TransitionFade"
import SelectLangs from "@/components/SelectLangs"
import Brand from "@/components/Brand"
import axiosClient from "@/config/AxiosClient"
import Spinner from "@/components/UI/Spinner"
import { CheckCircleIcon } from "@heroicons/vue/solid"
import { ClipboardCopyIcon } from "@heroicons/vue/outline"
import AutoMapper from "@/components/WizardNew/AutoMapper"
/* import FormConnection from "@/components/ChannelConnections/FormConnection" */
import Button from "primevue/button"
import { InformationCircleIcon } from "@heroicons/vue/solid"

export default {
  name: "DashboardAcumbamail",
  components: {
    SelectLangs,
    Brand,
    Spinner,
    TransitionFade,
    CheckCircleIcon,
    ClipboardCopyIcon,
    AutoMapper,
    /* FormConnection, */
    Button,
    InformationCircleIcon
  },
  data: function () {
    return {
      showPoweredBySetUp: false,
      driver: localStorage.getItem("driver"),
      account_uuid: localStorage.getItem("account_uuid"),
      accounts: JSON.parse(localStorage.getItem("account")),
      accountSelect: {},
      project_connection: {},
      multiAccount: false,
      completeIntegrations: false,
      loading: true,
      showCopyButton: false,
      copyAction: false,
      errors: {
        text: ""
      },
      channelFrom: {},
      channelTo: {},
      projectCredentialFrom: {},
      projectCredentialTo: {},
      project: {}
    }
  },
  computed: {
    shouldShowMapper() {
      return (this.completeIntegrations == true || (this.project_connection.integrations && this.project_connection.integrations.length == 0)) && !this.loading
    },
    shouldShowIntegration() {
      return this.project_connection.integrations && this.project_connection.integrations.length && !this.completeIntegrations
    }
  },
  methods: {
    async getIntegration() {
      this.loading = true

      let response = await axiosClient.get(`/api/2.0/account/integrations/${this.accountSelect.uuid}`, { headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` } })
      this.project_connection = response.data
      if (this.project_connection && (!this.project_connection.integrations || this.project_connection.integrations.length == 0)) {
        await this.createIntegration()
      } else {
        this.loading = false
      }

      if (this.project_connection.integrations.find((f) => f.service.name == "Products")) {
        this.showCopyButton = true
      }
    },
    changeAccount(accountSelect) {
      this.accountSelect = accountSelect
      this.getIntegration()
    },
    async createIntegration() {
      this.loading = true
      let response = await axiosClient.post(`/api/2.0/account/create/integration/onclick/${this.account_uuid}`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` } })
      if (!response.data.channelFrom) {
        this.project_connection = response.data
      } else {
        this.channelFrom = response.data.channelFrom
        this.channelTo = response.data.channelTo
        this.projectCredentialFrom = response.data.project_credential_from
        this.projectCredentialTo = response.data.project_credential_to
        this.project = response.data.project
        this.completeIntegrations = true
      }
      this.loading = false
    },
    async finishIntegration(data) {
      this.loading = true
      let response = await axiosClient.post(`/api/2.0/account/finish/integration/${this.account_uuid}`, { integrations: data, project_connection_id: this.project_connection.id }, { headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` } })
      this.project_connection = response.data
      this.loading = false
      this.completeIntegrations = false
    },
    completeIntegration() {
      this.$refs.automapper.sendDataFinish()
    },
    copyButton() {
      this.copyAction = true
      this.$copyText(`${process.env.VUE_APP_URL_API}/api/2.0/projects/${this.accountSelect.project_id}/content/2.0/xml`)
      setTimeout(() => {
        this.copyAction = false
      }, 3000)
    },
    hasMultipleAccounts: function () {
      if (this.accounts.length > 1) {
        this.multiAccount = true
      }
    },
    async enableEdit() {
      this.loading = true
      let resChannelFrom = await axiosClient.get("api/2.0/channels", { params: { include: "services.service_config" } })
      let resChannelTo = await axiosClient.get("api/2.0/channels/19", { params: { include: ["services_output.service_config"] } })
      this.channelFrom = resChannelFrom.data.data.find((f) => f.data_json.driver == this.driver)
      this.channelTo = resChannelTo.data.data
      let filter = {}
      filter["filter[channel_id][=]"] = 19
      let resCredentialTo = await axiosClient.get(`api/2.0/project/${this.project_connection.project_id}/credentials`, { params: filter })
      this.projectCredentialTo = resCredentialTo.data.data[0]
      filter["filter[channel_id][=]"] = this.channelFrom.id
      let resCredentialFrom = await axiosClient.get(`api/2.0/project/${this.project_connection.project_id}/credentials`, { params: filter })
      this.projectCredentialFrom = resCredentialFrom.data.data[0]
      this.completeIntegrations = true
      this.loading = false
    },
    async reactiveIntegrations(){
      await axiosClient.get(`/api/2.0/account/reactive/integration`, {params: {project_connection_id: this.project_connection.id} })
      this.project_connection.is_active = true
      this.$toast.success(this.$t("mailupView.reactiveSuccess"))
    }
  },
  created() {
    this.accountSelect = this.accounts[0]
    axiosClient.defaults.headers["X-Tenant"] = this.accountSelect.tenant.uuid
    this.getIntegration()
    this.hasMultipleAccounts()
  }
}
</script>
